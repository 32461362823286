
import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`accordion-list ${isActive ? 'active' : ''}`}>
      <div className="accordion-title" onClick={toggleAccordion}>
        <div className="question">{title}</div>
        <div className="icon">{isActive ? <FaMinus /> : <FaPlus />}</div>
      </div>
      {isActive && <div className="answer">{content}</div>}
    </div>
  );
};
export default Accordion;

